import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { questionTypeQuestion } from 'constants/questionTypes';

const Slider = ({
  id,
  question,
  saveUpdatedAnswers,
  currentQuestionDet,
}) => {
  // Parse scale from question.scale (expected format "0-5" or "0-10")
  const [scaleRange, setScaleRange] = useState(question?.scale?.split('-') || ['0', '10']);
  const [min, setMin] = useState(parseInt(scaleRange[0], 10));
  const [max, setMax] = useState(parseInt(scaleRange[1], 10));
  const [range, setRange] = useState(max - min);

  const [sliderValue, setSliderValue] = useState((min + max) / 2);
  const [isDragging, setIsDragging] = useState(false);
  const [clickedVal, setClickedVal] = useState(false);

  useEffect(() => {
    if (question?.scale) {
      const newScaleRange = question.scale.split('-');
      const newMin = parseInt(newScaleRange[0], 10);
      const newMax = parseInt(newScaleRange[1], 10);
      setScaleRange(newScaleRange);
      setMin(newMin);
      setMax(newMax);
      setRange(newMax - newMin);
      setSliderValue((newMin + newMax) / 2);
    }
  }, [question]);

  useEffect(() => {
    if (currentQuestionDet !== "" && currentQuestionDet !== undefined && currentQuestionDet !== null) {
      setSliderValue(currentQuestionDet);
      setClickedVal(true);
    } else {
      setSliderValue((min + max) / 2);
      setClickedVal(false);
    }
  }, [currentQuestionDet, min, max]);

  const handleClick = (e) => {
    const track = document.getElementById('slider-track');
    if (!track) return;
    const rect = track.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const newValue = Math.round((x / rect.width) * range + min);
    setSliderValue(newValue);
    saveValue(newValue);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const saveValue = (newValue) => {
    setClickedVal(true);
    SaveResponse(newValue);
  };

  function SaveResponse(newValue) {
    // Adjust if needed depending on how saveUpdatedAnswers should be called
    saveUpdatedAnswers(newValue);
  }

  const lerpColor = (color1, color2, t) => {
    const r = color1.r + t * (color2.r - color1.r);
    const g = color1.g + t * (color2.g - color1.g);
    const b = color1.b + t * (color2.b - color1.b);
    return { r, g, b };
  };

  const getThumbColor = () => {
    // Use a normalized t between 0 and 1
    const t = (sliderValue - min) / range;
    const color1 = { r: 248, g: 168, b: 176 };
    const color2 = { r: 254, g: 205, b: 141 };
    const color3 = { r: 149, g: 230, b: 210 };

    // Keep the 0.5232 breakpoint as a percentage of the range
    const breakpoint = 0.5232;
    if (t < breakpoint) {
      const newT = t / breakpoint;
      const interpolatedColor = lerpColor(color1, color2, newT);
      return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
    } else {
      const newT = (t - breakpoint) / (1 - breakpoint);
      const interpolatedColor = lerpColor(color2, color3, newT);
      return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
    }
  };

  const thumbPosition = {
    left: `calc(${((sliderValue - min) / range) * 100}% - 10px )`,
  };

  const dotCount = range + 1;  
  const dots = Array.from({ length: dotCount }, (_, index) => {
    const dotValue = (index / (dotCount - 1)) * 100;
    return (
      <Dot
        key={index}
        style={{ left: `${dotValue}%` }}
        id={`dot_${index}_${id}`}
      />
    );
  });

  return (
    <Container>
      <TrackWrapper
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <Track id={'slider-track'}>
          <DotsContainer id={`dots_${id}`}>{dots}</DotsContainer>
          <Thumb
            style={{
              ...thumbPosition,
              backgroundColor: getThumbColor(),
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            id={`thumb_${id}`}
          >
            <Tooltip clicked={clickedVal} id={`tip_${id}`}>
              {Math.round(sliderValue)}
              <Triangle />
            </Tooltip>
          </Thumb>
        </Track>
      </TrackWrapper>

      <ScaleLabelsContainer>
        <ScaleLabel>
          {question?.qualifier?.[0] || 'Strongly Disagree'}
        </ScaleLabel>
        <ScaleLabel>
          {question?.qualifier?.[1] || 'Neutral'}
        </ScaleLabel>
        <ScaleLabel>
          {question?.qualifier?.[2] || 'Strongly Agree'}
        </ScaleLabel>
      </ScaleLabelsContainer>
    </Container>
  );
};

export default Slider;

const Container = styled.div`
  width: 100%;
  padding-top: 70px;
  user-select: none;
`;

const ScaleLabel = styled.span`
  font-size: 14px;
`;

const ScaleLabelsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const TrackWrapper = styled.div`
  position: relative;
  height: calc(10px + 10px); 
  cursor: pointer;
  user-select: none;
`;

const Track = styled.div`
  width: 100%;
  height: 6px;
  background: linear-gradient(
    to right,
    #f8a8b0 0%,
    #fecd8d 52.32%,
    #95e6d2 100%
  );
  position: relative;
  border-radius: 5px;
  cursor: pointer;
`;

const Thumb = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
  border: 4px solid white;
  box-shadow: 0px 8px 20px rgba(63, 134, 254, 0.08);
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #666d79;
  border-radius: 8px;
  width: 33px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.clicked ? '#FFFFFF' : '#D6DBDF')};
  font-size: 14px;
  font-weight: ${(props) => (props.clicked ? '700' : '400')};
  margin-bottom: 8px;
`;

const Triangle = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0;
  border-color: #666d79 transparent transparent;
`;

const DotsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  display: flex;
  justify-content: space-between;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
`;