
import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

import Chat from 'assets/images/chat.png'



const Intro = ({ createdBy, onClick }) => {


    return (
        <Container>
            <Content>

            <Image src={Chat} alt="Image description" />
            <T1>
            Welcome to <Blue>innerlogic</Blue>, a safe and secure platform for collecting feedback.
            </T1>

            <Text>This survey is being sent to you by <strong>{createdBy}</strong>.</Text>

               
            </Content>

            <ButtonArea>
                <Button onClick={()=>onClick()}>Begin survey</Button>
            </ButtonArea>
        </Container>
    )

}

export default Intro;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif;
`

const Content = styled.div`
    max-width: 600px;
    width: 100%;
    padding: 40px;
    border-radius:20px;
    background-color: #F8FAFF;

    @media(max-width:768px){
        font-size:24px;
    }
`

const Image = styled.img`
  width: 100px;
  display: inline;
  vertical-align: middle;
  margin-right: 0.25em;
  margin-bottom:30px;

    @media(max-width:768px){
        width: 50px;
    }
`;

const Text = styled.div`
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;
    line-height:1.5;
`

const Blue = styled.span`
    color: #2d70e2;
    font-weight:700;
`

const ButtonArea = styled.div`
    margin-top: 20px;
    display: flex;
    width:100%;
    justify-content: flex-end;
    max-width:600px;
    padding:20px;
`

const Button = styled.button`
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    padding: 10px 20px;
    color: white;
    background-color: #2d70e2;
    border-radius: 8px;
    border: none;
    cursor: pointer;
`