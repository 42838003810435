import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

import Lock from 'assets/images/lock.png'



const Privacy = ({ createdBy, onClick,isAnonymous }) => {

    const getAnonymityStatement = () => {
        if (isAnonymous) {
            return (
                <T1>
                    Your responses are <strong>anonymous</strong> and will never be attached to <Blue>your identity.</Blue>
                </T1>
            )
        } else {
            return (
                <T1>
                    Your responses are <strong> <u>not</u></strong>  anonymous and <Blue>{createdBy}</Blue> will be able to view your direct responses.
                </T1>
            )
        }
    }

    return (
        <Container>
            <Content>
                <Image src={Lock} alt="Image description" />
                {getAnonymityStatement()}
              
            </Content>

            <ButtonArea>
                <Button onClick={()=>onClick()}>I understand</Button>
            </ButtonArea>
        </Container>
    )

}

export default Privacy;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif;
`

const Content = styled.div`
    max-width: 600px;
    width: 100%;
    padding: 40px;
    border-radius:20px;
    background-color: #F8FAFF;

    @media(max-width:768px){
        font-size:24px;
    }
`

const Image = styled.img`
  width: 40px;
  display: inline;
  vertical-align: middle;
  margin-right: 0.25em;
  margin-bottom:30px;

    @media(max-width:768px){
        width: 50px;
    }
`;

const Blue = styled.span`
    color: #2d70e2;
    font-weight:700;
`

const Text = styled.div`
`


const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;
    line-height:1.5;
`


const ButtonArea = styled.div`
    margin-top: 20px;
    display: flex;
    width:100%;
    justify-content: flex-end;
    max-width:600px;
    padding:20px;
`

const Button = styled.button`
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    padding: 10px 20px;
    color: white;
    background-color: #2d70e2;
    border-radius: 8px;
    border: none;
    cursor: pointer;
`