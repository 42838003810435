import React from 'react';
import Slider from './Scale'; // Assuming the above code is in Slider.js
import styled,{keyframes,css} from 'styled-components';


const QuestionComponent = ({
  question,
  saveUpdatedAnswers,
  currentQuestionDet,
  handlePreviousQuestion,
handleNextQuestion,
displayedQuestionIndex,
totalQuestions,
isFading
}) => {
  return (
    <Card isFading={isFading}>
      <h2>{question.question}</h2>
      <Slider
        id={question.id}
        question={question}
        saveUpdatedAnswers={saveUpdatedAnswers}
        currentQuestionDet={currentQuestionDet}
      />


    <ButtonContainer>
      {displayedQuestionIndex > 1 && (
          <BackButton onClick={handlePreviousQuestion}>Back</BackButton>
        ) || (
          <div style={{ width: "100px" }}></div>
        )}
        <NextButton onClick={handleNextQuestion} disabled={currentQuestionDet === ''}>
          {displayedQuestionIndex < totalQuestions ? "Next" : "Submit"}
        </NextButton>
      </ButtonContainer>
    </Card>
  );
};

export default QuestionComponent;


const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  overflow: hidden;
  padding: 30px;
  margin-top: 20px;
  flex-direction: column;
  position: relative;
  animation: ${({ isFading }) =>
  isFading ? css`${fadeOut} 0.5s ease-out forwards` : css`${fadeIn} 0.5s ease-out forwards`};
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;

const BackButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #2D70E2;
  background-color: #EBF1FD;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const NextButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

    &:disabled {
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
    }
`;